define("discourse/plugins/discourse-fingerprint/discourse/controllers/fingerprint-report", ["exports", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/decorators", "discourse/plugins/discourse-fingerprint/discourse/components/modal/fingerprint-details"], function (_exports, _controller, _object, _service, _ajax, _decorators, _fingerprintDetails) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FingerprintReportController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    queryParams = ["username"];
    username = null;
    user = null;
    matches = [];
    fingerprints = [];
    init() {
      super.init(...arguments);
      this.update();
    }
    showReport(user, username) {
      return user && username;
    }
    static #_2 = (() => dt7948.n(this.prototype, "showReport", [(0, _decorators.default)("user", "username")]))();
    update(username) {
      this.setProperties({
        fingerprints: []
      });
      if (username) {
        return (0, _ajax.ajax)("/admin/plugins/fingerprint/user_report", {
          type: "GET",
          data: {
            username
          }
        }).then(response => {
          const ignoredIdsSet = new Set(response.ignored_ids);
          const users = {};
          Object.values(response.users).forEach(user => {
            user.ignored = ignoredIdsSet.has(user.id);
            users[user.id] = _object.default.create(user);
          });
          const fingerprints = response.fingerprints.map(fingerprint => {
            fingerprint.user_ids = fingerprint.user_ids || [];
            fingerprint.users = fingerprint.user_ids.map(id => users[id]);
            return _object.default.create(fingerprint);
          });
          this.setProperties({
            user: response.user,
            users,
            fingerprints
          });
        });
      } else {
        return (0, _ajax.ajax)("/admin/plugins/fingerprint").then(response => {
          const users = {};
          Object.values(response.users).forEach(user => {
            users[user.id] = _object.default.create(user);
          });
          const fingerprints = response.fingerprints.map(fingerprint => {
            fingerprint.user_ids = fingerprint.user_ids || [];
            fingerprint.users = fingerprint.user_ids.map(id => users[id]);
            return _object.default.create(fingerprint);
          });
          this.setProperties({
            fingerprints,
            flagged: response.flagged.map(o => _object.default.create(o))
          });
        });
      }
    }
    updateUsername(selected) {
      this.set("username", selected.firstObject);
      this.update(selected.firstObject);
    }
    static #_3 = (() => dt7948.n(this.prototype, "updateUsername", [_object.action]))();
    viewReportForUser(user) {
      this.set("username", user.username);
      return this.update(user.username);
    }
    static #_4 = (() => dt7948.n(this.prototype, "viewReportForUser", [_object.action]))();
    showFingerprintData(data) {
      const dataStr = {};
      Object.keys(data).forEach(key => {
        dataStr[key] = data[key] !== null && typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key];
      });
      this.modal.show(_fingerprintDetails.default, {
        model: {
          data: dataStr
        }
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "showFingerprintData", [_object.action]))();
    flag(type, fingerprint, remove) {
      return (0, _ajax.ajax)("/admin/plugins/fingerprint/flag", {
        type: "PUT",
        data: {
          type,
          value: fingerprint.value,
          remove
        }
      }).then(() => {
        if (type === "hide") {
          fingerprint.set("hidden", !remove);
        } else if (type === "silence") {
          fingerprint.set("silenced", !remove);
        }
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "flag", [_object.action]))();
    ignore(otherUser, remove) {
      return (0, _ajax.ajax)("/admin/plugins/fingerprint/ignore", {
        type: "POST",
        data: {
          username: this.username,
          other_username: otherUser.username,
          remove
        }
      }).then(() => {
        otherUser.set("ignored", !remove);
      });
    }
    static #_7 = (() => dt7948.n(this.prototype, "ignore", [_object.action]))();
  }
  _exports.default = FingerprintReportController;
});